<template>
    <div class="container" style="padding-top: 10%;">
    </div>
</template>

<script>
import store from '@/store';
import auth from '@/auth';

export default {
  name: 'logout',
  beforeCreate () {
    // store.commit('ws_status', false);
    // auth.logout(this, '/login');
  },
  mounted: function () {
    console.log('logout ..');
    auth.logout(this, '/login');
  }
};
</script>

